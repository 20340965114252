// import * as firebase from "firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyAl4BgUkbyF-Bzetb-NJsrEHHqX03APlww",
  authDomain: "kelolaspj.firebaseapp.com",
  databaseURL:
    "https://kelolaspj-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "kelolaspj",
  storageBucket: "kelolaspj.appspot.com",
  messagingSenderId: "812180631823",
  appId: "1:812180631823:web:35022c50777fc362b58e7a",
  measurementId: "G-VW251MYTWN",
};
firebase.initializeApp(config);
firebase.firestore();
firebase.functions();
firebase.analytics();

// firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
