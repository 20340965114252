import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../store/rootReducer";
import { reduxFirestore } from "redux-firestore"; // not configured
import { getFirebase } from "react-redux-firebase";
import firebase from "../config/fbConfig";

const middlewares = [thunk.withExtraArgument(getFirebase)];
const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares), reduxFirestore(firebase))
);
export { store };
