const initState = {
  loading: false,
  sidebarShow: "responsive",
  lastPrinted: null,
  transaction: false,
};
const projectReducer = (state = initState, { type, ...rest }) => {
  switch (type) {
    case "ON_LOADING":
      return { ...state, loading: true };
    case "OFF_LOADING":
      return { ...state, loading: false };
    case "set":
      return { ...state, ...rest };
    case "PRINT_TRANSACTION":
      return {
        ...state,
        ...rest,
        lastPrinted: "transaction",
      };
    case "PRINT_USER_DETAIL":
      return {
        ...state,
        lastPrinted: "userDetail",
      };

    default:
      return state;
  }
};
export default projectReducer;
